<template>
  <div>
    <b-form class="form" v-on:submit.prevent="onSubmit">
      <b-form-group
        label-for="from"
        :label="$t('EDUCATION.FROM')"
        label-cols-lg="2"
      >
        <b-form-datepicker
          id="from"
          class="form-control h-auto"
          v-model="$v.form.from.$model"
          :state="validateState('from')"
        >
        </b-form-datepicker>
      </b-form-group>

      <b-form-group
        label-for="to"
        :label="$t('EDUCATION.TO')"
        label-cols-lg="2"
      >
        <b-form-datepicker
          id="to"
          class="form-control h-auto"
          v-model="$v.form.to.$model"
          :state="validateState('to')"
        >
        </b-form-datepicker>
      </b-form-group>

      <b-form-group
        id="remark"
        label-for="remark"
        :label="$t('JOB.REMARK')"
        label-cols-lg="2"
      >
        <b-form-textarea
          id="remark"
          v-model="$v.form.remark.$model"
          :placeholder="$t('JOB.REMARK1')"
          :state="validateState('remark')"
        ></b-form-textarea>

        <b-form-invalid-feedback v-if="!$v.form.remark.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="form-group d-flex justify-content-end">
        <button
          type="submit"
          ref="department-form-submit"
          class="btn btn-primary font-weight-bolder"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
  name: "PersonalLeaveEntitlementApplyLeaveForm",
  mixins: [validationMixin],
  props: {
    isBusy: {
      required: true,
      default: false,
    },
  },
  data() {
    return {
      form: {
        type: "",
        balance: "",
        from: "",
        to: "",
        days: "",
        session: "",
        remark: "",
      },
      type: [
        { value: "1", text: "Annual" },
        { value: "2", text: "Medical" },
        { value: "3", text: "Emergency" },
      ],
      status: [
        { value: "1", text: "Approve" },
        { value: "2", text: "Reject" },
      ],
      image: null,
      image_url: "",
      formBusy: false,
      mainProps: { blank: true, width: 200, height: 200, class: "m1" },
    };
  },
  validations: {
    form: {
      from: {
        required,
        maxValue(val, { to }) {
          const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
          dayjs.extend(isSameOrBefore);
          return dayjs(val).isSameOrBefore(dayjs(to));
        },
      },
      to: {
        required,
        maxValue(val, { from }) {
          const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
          dayjs.extend(isSameOrAfter);
          return dayjs(val).isSameOrAfter(dayjs(from));
        },
      },
      days: {},
      remark: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Application Form HANTU :", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$emit("submit-clicked", this.form);
    },
  },
};
</script>

<style scoped></style>
